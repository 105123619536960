@import '../../../../../../styles/shared';

.wrapper {
  overflow: hidden;
}

.ctaButton {
  margin-top: 48px;

  @include mq($from: desktop) {
    margin-top: 68px;
  }
}

.itemContainer {
  text-align: left;
  padding: 50px 20px 50px 20px;
  border-radius: 12px;
  height: 100%;

  @include mq($from: tablet) {
    padding: 50px 50px 50px 75px;
  }
}

.itemRowContainer {
  flex-direction: column-reverse;

  @include mq($from: desktop) {
    flex-direction: row;
  }
}

.itemLeft {
  margin-top: 18px;
  display: inline-block;
  padding-left: 18px;

  @include mq($from: tablet) {
    margin-top: 18px;
    padding: 0 36px 36px 0;
  }
}

.itemRight {
  margin-top: 12px;

  @include mq($from: desktop) {
    padding-left: 18px;
  }
}

.holder {
  margin-top: 1em;
  max-width: 150px;

  @include mq($from: tablet) {
    max-width: 100%;
  }
}

:global(.hub-testimonials .slick-slider) {
  width: 100%;
  margin: 0 auto;

  @include mq($from: tablet) {
    width: 80%;
  }
}

:global(.hub-testimonials .slick-list) {
  overflow: visible;
}

:global(.hub-testimonials .slick-slide) {
  margin: 0 20px;
}

// Need to revert colors according to design
@include hub-dark-theme() {
  .itemContainer {
    color: var(--hub-module-light-color);
    background-color: var(--hub-module-light-background-color);
  }

  .itemLeft {
    border-left: 1px solid var(--hub-module-light-line-color);

    @include mq($from: tablet) {
      border-left: none;
      border-right: 1px solid var(--hub-module-light-line-color);
    }
  }
}

@include hub-light-theme() {
  .itemContainer {
    background-color: var(--hub-module-dark-background-color);
    color: var(--hub-module-dark-color);
  }

  .itemLeft {
    border-left: 1px solid var(--hub-module-dark-line-color);

    @include mq($from: tablet) {
      border-left: none;
      border-right: 1px solid var(--hub-module-dark-line-color);
    }
  }
}

:global(.dark-theme .slick-dots li button) {
  border: 2px solid var(--hub-module-dark-color);
}

:global(.dark-theme .slick-dots li.slick-active button) {
  &:before {
    color: var(--hub-module-dark-color);
  }
}

:global(.light-theme .slick-dots li button) {
  border: 2px solid var(--hub-module-light-color);
}

:global(.light-theme .slick-dots li.slick-active button) {
  &:before {
    color: var(--hub-module-light-color);
  }
}
