@import '../../../../../../../styles/shared';

.wrapper {
  height: 100%;
  width: 100%;
  outline: none;
  position: relative;

  &:before {
    content: '“';
    font-weight: 800;
    font-size: 60px;
    line-height: 60px;
    top: 12px;
    left: 20px;
  }

  &:after {
    content: '”';
    font-weight: 800;
    font-size: 60px;
    line-height: 60px;
    bottom: -14px;
    right: 20px;
  }

  &:before,
  &:after {
    display: block;
    position: absolute;
    font-family: var(--hub-module-heading-font-family);
  }
}

// Need to revert colors according to design
@include hub-dark-theme() {
  .wrapper {
    &:before,
    &:after {
      color: var(--hub-module-light-color);
    }
  }
}

@include hub-light-theme() {
  .wrapper {
    &:before,
    &:after {
      color: var(--hub-module-dark-color);
    }
  }
}
